<template>
	<CvSkillElement
		:user-skills-obj="userSkillsObj"
		:predefined-skills-obj="predefinedSkillsObj"
		:profile-or-match-overview="profileOrMatchOverview"
		:view-only="viewOnly"
		:class="{
			'pb-6 border-b border-color-grey-lightest': profileOrMatchOverview
		}"
		@edit-cv-skill="editCvSkill"
	>
		<template #edit>
			<!-- "edit"-button if there are already some skills, "add"-button if empty -->
			<template v-if="userSkillsObj && userSkillsObj.items && userSkillsObj.items.length">
				<HokIcon
					v-if="!viewOnly"
					name="icon:pencil"
					color="main"
					:size="5"
					pointer
					class="inline"
					@click="editCvSkill(false)"
				/>
			</template>
			<template v-else>
				<HokButton
					v-if="!viewOnly"
					is-text
					color="main"
					font-weight="light"
					:data-cy="`add-skill-${userSkillsObj.category && userSkillsObj.category.type}`"
					@click="editCvSkill(true)"
				>
					+ Hinzufügen
				</HokButton>
			</template>
		</template>
	</CvSkillElement>
</template>

<script lang="ts">
import CvSkillElement from '@hokify/hokifycv-components-nuxt3/lib/components/CvSkillElement.vue';
import type { IAPIUserCvSkill, IAPICvSkill, IAPILoginUser } from '@hokify/common';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	components: { CvSkillElement },
	data() {
		return {
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to-skill');
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		async editCvSkill(addNewSkill = false) {
			if (!this.viewOnly) {
				let title;
				let secondTitle;
				const pageTitle = `${this.userSkillsObj.title} hinzufügen`;

				switch (this.userSkillsObj.title) {
					case 'Führerscheine':
						title = 'Welche Führerscheine besitzt du?';
						break;
					case 'Hobbies':
						title = 'Welche Hobbies hast du?';
						break;
					case 'Sprachen':
						title = 'Welche Sprachen sprichst du?';
						secondTitle = 'Wie gut sprichst du ';
						break;
					case '"EDV/IT-Kenntnisse"':
						title = 'Welche EDV/IT-Kenntnisse besitzt du?';
						secondTitle = 'Wie gut kannst du ';
						break;
					default:
						title = `Welche ${this.userSkillsObj.title} möchtest du hinzufügen?`;
						secondTitle = '';
				}
				const EditCvSkillComponent = markRaw(
					defineAsyncComponent(() => import('./EditCvSkill.vue'))
				);

				try {
					await this.$page.push(
						EditCvSkillComponent,
						{
							addNewSkill,
							title,
							secondTitle,
							userSkillsObj: this.userSkillsObj,
							predefinedSkillsObj: this.predefinedSkillsObj
						},
						{
							pageTitle,
							name: 'editCvSkill',
							done: () => {
								this.EventBus.$off('end-go-to-skill');
							}
						}
					);
					this.EventBus.$on('end-go-to-skill', () => this.$page.goBack());
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
			}
		}
	},
	props: {
		userSkillsObj: {
			type: Object as PropType<IAPIUserCvSkill>,
			default: () => ({})
		},
		predefinedSkillsObj: {
			type: Object as PropType<IAPICvSkill>,
			default: () => ({
				/* empty object */
			})
		},
		profileOrMatchOverview: { type: Boolean, default: false },
		viewOnly: { type: Boolean, default: false }
	}
});
</script>
