<template>
	<section :class="{ 'hokbox mb-5': styling === 'company' }">
		<!-- show all skills-->
		<div class="bg-color-white rounded-lg shadow-xl px-6 pt-8 mb-4">
			<CvSkillElementEditMode
				v-for="(skill, index) in allSkillsSorted"
				:key="index"
				:user-skills-obj="skill.userSkillsObj"
				:predefined-skills-obj="skill.predefinedSkillsObj"
				:view-only="viewOnly"
				profile-or-match-overview
			/>
		</div>
	</section>
</template>
<script lang="ts">
import type { IAPICvSkill, IAPILoginUser, IAPIUserCvSkill } from '@hokify/common';

import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useUserProfileStore } from '@/stores/user-profile';
import { useValuesStore } from '@/stores/values';
import CvSkillElementEditMode from './CvSkillElementEditMode.vue';

interface IUserCvSkill extends IAPIUserCvSkill {
	userSkillsObj?: {
		category: {
			type: IPredefinedCvSkill['type'];
			value: IPredefinedCvSkill['value'];
			_id: IPredefinedCvSkill['_id'];
		};
		title: IPredefinedCvSkill['value'];
	};
	predefinedSkillsObj?: IPredefinedCvSkill;
}

interface IPredefinedCvSkill {
	_id: string;
	type: string;
	value: string;
}

export default defineComponent({
	components: { CvSkillElementEditMode },
	data() {
		const predefinedCvSkills: any[] = [];

		return {
			predefinedCvSkills
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		allSkillsSorted(): IUserCvSkill[] {
			const allSkills = this.computeSkills(this.userProfileStore.obj?.cvInfo?.skills);
			const sortedSkills: IAPIUserCvSkill[] = [];
			allSkills.forEach(skill => {
				if (skill.predefinedSkillsObj.type === 'language') {
					sortedSkills[0] = skill;
				} else if (skill.predefinedSkillsObj.type === 'edv-and-it') {
					sortedSkills[1] = skill;
				} else if (skill.predefinedSkillsObj.type === 'drivinglicence') {
					sortedSkills[2] = skill;
				} else {
					sortedSkills.push(skill);
				}
			});
			return sortedSkills;
		},
		...mapStores(useUserProfileStore, useValuesStore)
	},
	async mounted() {
		try {
			this.predefinedCvSkills = await this.valuesStore.getPredefinedCvSkills();
		} catch (err) {
			this.$nuxt.$errorHandler(err);
		}
	},
	methods: {
		computeSkills(skills?: IAPIUserCvSkill[]) {
			const allSkills: any[] = [];

			// return all predefinedSkills
			if (this.predefinedCvSkills) {
				if (!this.showHobbies) {
					this.predefinedCvSkills = this.predefinedCvSkills.filter(
						predefinedCvSkill => predefinedCvSkill?.type !== 'hobby'
					);
				}
				this.predefinedCvSkills.forEach((predefinedCvSkill: IAPICvSkill) => {
					let userObjSkill;
					const isNotInUserObj = !skills?.some(skillObj => {
						if (skillObj?.category?._id === predefinedCvSkill._id) {
							userObjSkill = skillObj;
							return true;
						}
						return false;
					});

					if (isNotInUserObj) {
						// predefinedSkills which are not in userObj
						allSkills.push({
							userSkillsObj: {
								category: {
									type: predefinedCvSkill.type,
									value: predefinedCvSkill.value,
									_id: predefinedCvSkill._id
								},
								title: predefinedCvSkill.value
							},
							predefinedSkillsObj: predefinedCvSkill
						});
					} else if (userObjSkill) {
						// predefinedSkills which are already in userObj
						allSkills.push({
							userSkillsObj: {
								...userObjSkill,
								title: userObjSkill.category?.value || userObjSkill.title
							},
							predefinedSkillsObj: predefinedCvSkill
						});
					}
				});
			}

			// custom skills
			if (skills) {
				skills.forEach(userSkill => {
					// skills in userObj which are not predefined
					const template = !!(
						this.predefinedCvSkills &&
						this.predefinedCvSkills.find(
							category =>
								(userSkill &&
									userSkill.category &&
									(category as any)._id === userSkill.category._id) ||
								false
						)
					);

					if (!template) {
						if (this.showHobbies || userSkill?.category?.type !== 'hobby') {
							allSkills.push({
								userSkillsObj: userSkill,
								predefinedSkillsObj: {}
							});
						}
					}
				});
			}

			return allSkills;
		}
	},
	props: {
		styling: { type: String, default: 'user' },
		showHobbies: { type: Boolean, default: true },
		viewOnly: { type: Boolean, default: false }
	}
});
</script>
